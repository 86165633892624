import { string } from 'zod'

export const tryToMakeEmbedLookerUrl = (maybeUrl?: string | null) => {
  if (!maybeUrl) {
    return null
  }
  try {
    const plainUrl = new URL(maybeUrl)
    plainUrl.pathname = `/embed${plainUrl.pathname}`
    plainUrl.searchParams.append('allow_login_screen', 'true')
    return plainUrl
  } catch (err) {
    // that's ok. maybe users just typing
  }

  return null
}

export const getEmbedUrlWithLoginScreen = (url: string) => {
  const newUrl = new URL(url)
  newUrl.searchParams.append('allow_login_screen', 'true')
  return newUrl.href
}

export const lookerValidator = string()
  .trim()
  .url()
  .regex(/^https:\/\/[\w\d-]+\.looker\.com\/[\w\d/\-._]*$/)

export const tableauValidator = string()
  .trim()
  .url()
  .regex(/^https:\/\/[\w\d-.]+\.tableau\.com\/[\w\d/\-#:=?._]*$/)
