import {
  clickupIntegrationPreferences,
  jiraPreferencesRequests,
  lookerIntegrationPreferences,
  tableauIntegrationPreferences,
} from '@src/api/integrations'
import { RequestInterfaceNew } from '@src/interfaces'
import { UpdateTypes } from '@src/interfaces/kpis'
import { string } from 'yup'

export interface IntegrationForm {
  server: string
  enabled: boolean
  id: number
  client_id?: string
  server_id?: string
  secret_value?: string
  owner?: { id: number }
  client_guid?: string
  redirect_uri?: string
  is_external_app?: boolean
}

export const integrationApiFormMap: Record<
  UpdateTypes,
  RequestInterfaceNew<IntegrationForm> | undefined
> = {
  [UpdateTypes.looker]: lookerIntegrationPreferences,
  [UpdateTypes.roadmap]: jiraPreferencesRequests,
  [UpdateTypes.clickup]: clickupIntegrationPreferences,
  [UpdateTypes.manual]: undefined,
  [UpdateTypes.sql]: undefined,
  [UpdateTypes.tableau]: tableauIntegrationPreferences,
}

export const serverNameValidators = {
  [UpdateTypes.looker]: {
    server: string()
      .trim()
      .url()
      .transform(val => val.replace(/\/+$/, ''))
      .matches(/^https:\/\/[\w\d-]+\.looker.com$/, 'Provide correct server URL'),
  },
  [UpdateTypes.roadmap]: {
    server: string()
      .trim()
      .url()
      .transform(val => val.replace(/\/+$/, ''))
      .matches(/^https:\/\/[\w\d-]+\.atlassian\.net$/, 'Provide correct server URL'),
  },
  [UpdateTypes.clickup]: undefined,
  [UpdateTypes.manual]: undefined,
  [UpdateTypes.sql]: undefined,
  [UpdateTypes.tableau]: {
    server: string()
      .trim()
      .url()
      .matches(
        /^https:\/\/[\w\d-.]+\.tableau\.com\/[\w\d/\-#:=?._]*$/,
        'Provide correct server URL',
      ),
  },
} as const
